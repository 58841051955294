import { ErrorMessage, Field, useFormikContext } from "formik";
import { useState } from "react";

import ButtonLoader from "../Loaders/ButtonLoader";
import Spinner from "../Spinner";
import { BankIcon } from "./BankIcon";
import { PaypalIcon } from "./PaypalIcon";
import { StripeIcon } from "./StripeIcon";
import axios from "axios";

const Icons = {
  Bank: <BankIcon />,
  Paypal: <PaypalIcon />,
  Stripe: <StripeIcon />,
  Custom: "Custom",
};

function Step5({
  values,
  isSubmitting,
  handlePaypal,
  handleStripe,
  loadingPaymentLink,
}) {
  const { setFieldValue, isValid } = useFormikContext();
  const [paymentLinkField, setPaymentLinkField] = useState("");

  const generatePaymentLink = async (paymentMethod) => {
    const paymentHandlers = {
      Stripe: handleStripe,
      Paypal: handlePaypal,
    };

    // Ensure the payment method is supported
    if (paymentHandlers[paymentMethod]) {
      try {
        const paymentLink = await paymentHandlers[paymentMethod]();

        if (!axios.isAxiosError(paymentLink)) {
          setPaymentLinkField(paymentLink);
          setFieldValue("paymentLink", values.paymentLink || paymentLink || "");
        } else {
          // Handle axios error specifically
          setPaymentLinkField("");
          setFieldValue("paymentLink", "");
        }
      } catch (error) {
        // Handle other possible errors here (network issues, unexpected errors)
        console.error("An error occurred:", error);
      }
    } else {
      console.error("Unsupported payment method:", paymentMethod);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 mb-4">
        {/* DISCOUNT */}
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label
              htmlFor="discount"
              className="block text-xs font-bold text-zinc-800"
            >
              Discount %
            </label>
            <span className="text-xs italic font-medium text-Neutral700">
              Optional
            </span>
          </div>
          <Field
            name="discount"
            type="number"
            id="discount"
            className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-full"
            placeholder="Enter percent discount"
            min={0}
            max={100}
            value={values.discount}
            onChange={(e) => {
              let newValue = e.target.value;
              if (newValue > 100) {
                newValue = 100;
              } else if (newValue < 0) {
                newValue = 0;
              }
              setFieldValue("discount", newValue);
            }}
          />
        </div>

        {/* PAYMENT METHOD */}
        <div className="space-y-2">
          <label htmlFor="paymentMethod" className="mb-2 text-xs font-bold">
            Payment Method
          </label>
          <div className="flex gap-2.5 items-center">
            <div className="flex flex-col w-full p-3 bg-white border rounded-lg">
              <button
                id="optionsButton"
                data-dropdown-toggle="options"
                className="flex items-center justify-between w-full text-sm text-gray-900 bg-transparent border-none outline-none"
                type="button"
                onClick={() => {
                  const options = document.getElementById("options");
                  options.classList.toggle("hidden");
                }}
              >
                <span className="sr-only">Open Payment Methods</span>
                {Icons[values.paymentMethod]}
                <svg
                  className="w-2.5 h-2.5 ml-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <div
                id="options"
                className="z-10 hidden bg-white rounded-lg text-zinc-800 py-2.5"
              >
                <ul
                  className="overflow-auto text-sm font-medium"
                  aria-labelledby="dropdownInformOptionsButton"
                >
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full gap-2 px-2 py-3 text-left rounded hover:bg-gray-100"
                      onClick={() => {
                        setFieldValue("paymentMethod", "Bank");
                        // setFieldValue("paymentLink", null);
                        document
                          .getElementById("options")
                          .classList.add("hidden");
                      }}
                    >
                      <BankIcon />
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full gap-2 px-2 py-3 text-left rounded hover:bg-gray-100"
                      // onClick={handleStripeClick}
                      onClick={() => {
                        setFieldValue("paymentMethod", "Stripe");
                        document
                          .getElementById("options")
                          .classList.add("hidden");
                      }}
                    >
                      <StripeIcon />
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full gap-2 px-2 py-3 text-left rounded hover:bg-gray-100"
                      // onClick={handlePaypalClick}
                      onClick={() => {
                        setFieldValue("paymentMethod", "Paypal");
                        document
                          .getElementById("options")
                          .classList.add("hidden");
                      }}
                    >
                      <PaypalIcon />
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full gap-2 px-2 py-3 text-left rounded text-gray-500 hover:bg-gray-100"
                      onClick={() => {
                        setFieldValue("paymentMethod", "Custom");
                        document
                          .getElementById("options")
                          .classList.add("hidden");
                      }}
                    >
                      Custom
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ErrorMessage name="paymentMethod">
            {(msg) => <div className="mt-2 text-sm text-red-600">{msg}</div>}
          </ErrorMessage>
        </div>

        {["Stripe", "Paypal", "Custom"].includes(values.paymentMethod) && (
          <div className="flex items-center gap-3">
            <input
              type="text"
              name="paymentLink"
              placeholder={`${values.paymentMethod} payment link`}
              disabled={values.paymentMethod === "Custom" ? false : true}
              onChange={(e) => {
                const url = e.target.value;
                setFieldValue("paymentLink", url);
              }}
              value={values.paymentLink || paymentLinkField}
              className="flex-grow p-2 text-xs border rounded border-Neutral400 text-Neutral700"
            />
            <button
              type="button"
              className={`relative p-2 overflow-hidden text-xs font-semibold text-white rounded bg-AccentRegular ${
                values.paymentMethod === "Custom" ? "hidden" : "block"
              }`}
              onClick={() => generatePaymentLink(values.paymentMethod)}
            >
              Generate
              {loadingPaymentLink && (
                <span className="absolute inset-0 grid place-items-center bg-AccentRegular">
                  <Spinner
                    size={16}
                    thumbColor="rgba(255,255,255,0.8)"
                    spinnerBg="rgba(255,255,255,0.35)"
                  />
                </span>
              )}
            </button>
          </div>
        )}

        {/* BANK DETAILS */}
        <div
          className={`flex flex-col w-full ${
            values.paymentMethod === "Bank" ? "" : "hidden"
          }`}
        >
          <label
            htmlFor="bankDetails"
            className="block mb-2 text-xs font-bold text-zinc-800"
          >
            Bank Details
          </label>
          <Field
            name="bankDetails"
            as="textarea"
            id="bankDetails"
            className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-full h-24"
            placeholder="Enter all bank details your client will need to complete the payment..."
            maxLength={1000}
          />
          {/* bottom right */}
          <div className="flex items-end justify-end text-xs text-gray-400">
            <span className="text-gray-500">
              {values.bankDetails?.length}/1000
            </span>
          </div>
          <ErrorMessage name="bankDetails">
            {(msg) => <div className="mt-2 text-sm text-red-600">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>

      <button
        type="submit"
        className="w-full p-2 py-3 text-xs font-bold tracking-wide uppercase rounded bg-Neutral300 text-AccentRegular hover:text-white not-disabled:hover:bg-AccentRegular disabled:text-Neutral600 disabled:cursor-not-allowed"
        disabled={
          isSubmitting ||
          !isValid ||
          (values.paymentMethod === "Bank" && values.bankDetails === "")
        }
      >
        {isSubmitting ? <ButtonLoader /> : "Save"}
      </button>
    </>
  );
}

export default Step5;

import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import InvoiceLoader from "../../components/ContentLoaders/InvoiceLoader";
import InvoiceForm from "../../components/Invoice-v2/InvoiceForm";
import EditInvoiceAndInvoiceTemplateModal from "../../components/Modals/EditInvoiceAndInvoiceTemplateModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import {
  PaypalOrderCreate,
  PaypalOrderUpdate,
  getInvoiceById,
  stripeOrderCreate,
  updateInvoice,
} from "../../store/actions/invoicesAction";
import { getTagColor } from "../../utils/getTagColor";

const allRequiredFields = [
  "invoiceName",
  "categoryTags",
  "clientName",
  "clientEmail",
  "clientCompanyName",
  "clientPhoneNumber",
  "clientAddress",
  "email",
  "fullName",
  "companyName",
  "address",
  "phoneNumber",
  "invoiceNumber",
  "currency",
  "invoiceDate",
  "dueDate",
  "services",
  "taxes",
  "paymentMethod",
  "bankDetails",
  "billingType",
];

const validationSchema = Yup.object().shape({
  // step
  step: Yup.number(),
  forceValidate: Yup.boolean(),

  // Step 1 fields (Template Details) ->
  invoiceName: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 1 || forceValidate,
    then: Yup.string().required("Invoice Name is Required").nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  categoryTags: Yup.array().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 1 || forceValidate,
    then: Yup.array().min(1, "Category Tags is Required"),
    otherwise: Yup.array().notRequired(),
  }),

  // Step 2 fields (Client Information) ->
  clientName: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 2 || forceValidate,
    then: Yup.string().required("Client Name is Required").nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  clientEmail: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 2 || forceValidate,
    then: Yup.string().nullable().email("Client Email is Invalid"),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  clientCompanyName: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 2 || forceValidate,
    then: Yup.string().nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  clientPhoneNumber: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 2 || forceValidate,
    then: Yup.string(),
  }),
  clientAddress: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 2 || forceValidate,
    then: Yup.string().nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  clientLogo: Yup.string(),
  clientVAT: Yup.string(),

  // Step 3 fields (My Information) ->
  email: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 3 || forceValidate,
    then: Yup.string().required("Email is Required").nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  fullName: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 3 || forceValidate,
    then: Yup.string().required("Full Name is Required").nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  companyName: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 3 || forceValidate,
    then: Yup.string().nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  address: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 3 || forceValidate,
    then: Yup.string().nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  phoneNumber: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 3 || forceValidate,
    then: Yup.string(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  VAT: Yup.string(),

  // Step 4 fields (Invoice Details) ->
  invoiceNumber: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 4 || forceValidate,
    then: Yup.string().required("Invoice Number is Required"),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  currency: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 4 || forceValidate,
    then: Yup.string().required("Currency is Required"),
    otherwise: Yup.string().notRequired(),
  }),
  invoiceDate: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 4 || forceValidate,
    then: Yup.string().required("Invoice Date is Required"),
    otherwise: Yup.string().notRequired(),
  }),
  dueDate: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 4 || forceValidate,
    then: Yup.string()
      .required("Due Date is Required")
      .when("invoiceDate", {
        is: (val) => val !== "",
        then: Yup.string().test(
          "is-greater",
          "Due Date must be greater than Invoice Date",
          function (value) {
            const { invoiceDate } = this.parent;
            const invoiceDateObj = new Date(invoiceDate).getTime();
            const dueDateObj = new Date(value).getTime();
            return dueDateObj > invoiceDateObj;
          }
        ),
      }),
    otherwise: Yup.string().notRequired(),
  }),
  vatPercentage: Yup.number().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 4 || forceValidate,
    then: Yup.number().when("is_VAT", {
      is: (val) => val === true,
      then: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable(),
      otherwise: Yup.number().notRequired().nullable(),
    }),
    otherwise: Yup.number().notRequired().nullable(),
  }),
  is_VAT: Yup.boolean(),
  taxes: Yup.array().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 4 || forceValidate,
    then: Yup.array().when("is_VAT", {
      is: (val) => val === false,
      then: Yup.array().of(
        Yup.object().shape({
          taxName: Yup.string().required("Tax Name is Required"),
          taxValue: Yup.number()
            .required("Tax Value is Required")
            .min(0, "Tax Value must be greater than 0")
            .nullable(),
        })
      ),
      otherwise: Yup.array().notRequired(),
    }),
    otherwise: Yup.array().notRequired(),
  }),

  // Step 5 fields (Services Details) ->
  services: Yup.array().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 5 || forceValidate,
    then: Yup.array()
      .min(1, "Minimum 1 Service is Required")
      .of(
        Yup.object().shape({
          serviceType: Yup.string()
            .required("Service Type is Required")
            .oneOf(["Hourly", "Fixed"]),
          serviceName: Yup.string().required("Service Name is Required"),
          servicePrice: Yup.number()
            .required("Service Price is Required")
            .min(1, "Service Price must be greater than 0")
            .nullable(),
          quantity: Yup.number().when("serviceType", {
            is: (val) => val === "Hourly",
            then: Yup.number()
              .required("Quantity is Required")
              .min(1, "Quantity must be greater than 0")
              .nullable(), // Only required when serviceType is 'Hourly'
            otherwise: Yup.number().nullable(), // Not required for non-hourly services
          }),
        })
      ),
    otherwise: Yup.array().notRequired(), // Not required if not on step 5
  }),

  // Step 6 fields (Payment Details) ->
  discount: Yup.number().nullable(),
  paymentMethod: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 6 || forceValidate,
    then: Yup.string().required("Payment Method is Required").nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  // bankDetails: Yup.string().notRequired().nullable(),
  bankDetails: Yup.string().when(["step", "forceValidate"], {
    is: (val, forceValidate) => val === 6 || forceValidate,
    then: Yup.string().when("paymentMethod", {
      is: (val) => val === "Bank",
      then: Yup.string().required("Bank Details is Required").nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  paymentLink: Yup.string()
    .url("Invalid URL")
    .when("paymentMethod", {
      is: (val) => val === "Paypal" || val === "Stripe" || val === "Custom",
      then: Yup.string()
        .url("Invalid URL")
        .required("Payment Link is Required")
        .nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  billingType: Yup.string().required("Required"),
  interval: Yup.string().when("billingType", {
    is: (val) => val === "recurring",
    then: Yup.string().required("Required"),
    otherwise: Yup.string().notRequired(),
  }),
  repeats: Yup.number().when("billingType", {
    is: (val) => val === "recurring",
    then: Yup.number().required("Required"),
    otherwise: Yup.number().notRequired(),
  }),
  startDate: Yup.string().when("billingType", {
    is: (val) => val === "recurring",
    then: Yup.string().required("Required").nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),

  // Invoice Status (one of = draft, template, sent, paid, overdue, cancelled)
  status: Yup.string().required("Required"),
});

const getEndDate = (startDate, interval, repeats, userDateFormat) => {
  const date = new Date(startDate);

  repeats = Number(repeats); // Ensure repeats is a number

  if (interval === "weekly") {
    date.setDate(date.getDate() + repeats * 7);
  }
  if (interval === "bimonthly") {
    const totalMonths = Math.floor(repeats / 2);
    date.setMonth(date.getMonth() + totalMonths);
    if (repeats % 2 === 0) {
      date.setDate(16);
    } else {
      date.setDate(1);
    }
  }
  if (interval === "monthly") {
    date.setMonth(date.getMonth() + repeats);
  }
  if (interval === "yearly") {
    date.setFullYear(date.getFullYear() + repeats);
  }

  return moment(date).format(userDateFormat);
};

function CreateInvoice() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const { clientId, invoiceId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const query = new URLSearchParams(window.location.search);
  const invoiceType = query.get("type");
  const invoiceInterval = query.get("interval");
  const invoiceRepeats = query.get("repeats");
  const invoiceStartDate = query.get("startDate");

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const cardId = params.get("code");

  const [initialValues, setInitialValues] = useState({
    invoiceName: "",
    categoryTags: "",
    clientName: "",
    clientEmail: "",
    clientCompanyName: "",
    clientPhoneNumber: "",
    clientAddress: "",
    clientLogo: "",
    clientVAT: "",
    email: "",
    fullName: "",
    companyName: "",
    address: "",
    phoneNumber: "",
    VAT: "",
    invoiceNumber: "",
    currency: "",
    invoiceDate: new Date(),
    dueDate: new Date().setDate(new Date().getDate() + 10),
    vatPercentage: null,
    is_VAT: true,
    taxes: [
      {
        taxName: "",
        taxValue: null,
      },
    ],
    services: [],
    discount: null,
    paymentMethod: "Bank",
    paymentLink: null,
    bankDetails: "",
    billingType: "standard",
    interval: 1,
    repeats: 1,
    startDate: moment().format("DD/MM/YYYY"),
    status: "draft",
    forceValidate: false,
  });

  const isTemplate = window.location.pathname.includes("invoice-creator");
  const [type, setType] = useState(invoiceType || "standard");
  const [interval, setIntervall] = useState(invoiceInterval || "weekly");
  const [repeats, setRepeats] = useState(invoiceRepeats || 1);
  const [startDate, setStartDate] = useState(
    invoiceStartDate || new Date().toLocaleDateString()
  );
  const [endDate, setEndDate] = useState();
  const [selectedDueDate, setSelectedDueDate] = useState(
    isTemplate
      ? moment(initialValues.dueDate, "DD/MM/YYYY").toDate()
      : moment(initialValues.dueDate).toDate()
  );
  const [isOpenTemplateModal, setIsOpenTemplateModal] = useState(false);
  const [activeFocusedTab, setActiveFocusedTab] = useState("");

  const clientDetail = useSelector((state) => state.client.clientDetail);

  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStripeLink, setLoadingStripeLink] = useState(false);
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);

  const invoice = useSelector((state) => state.invoice.invoice);
  const user = useSelector((state) => state.auth.user);
  const loggedInUser = useSelector((state) => state.auth.user);
  const userDateFormat = loggedInUser?.date_format;

  const [showRecurringBannerMenu, setShowRecurringBannerMenu] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [isCreateInvoice, setIsCreateInvoice] = useState(false);
  const modalColor = "Pink";

  useEffect(() => {
    if (!clientId) return;

    if (state) {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: state },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId, navigate));
      }
    }
  }, [clientDetail.data._id, clientId, dispatch, navigate, state]);

  useEffect(() => {
    if (invoiceId) {
      setIsLoading(true);
      dispatch(getInvoiceById(clientId, invoiceId, setIsLoading));
    }
  }, [clientId, dispatch, invoiceId]);

  useEffect(() => {
    if (invoiceId && invoice) {
      setInitialValues({
        ...invoice,
        categoryTags: invoice.categoryTags?.length
          ? invoice?.categoryTags?.map((item, i) => {
              const { bgColor: optionColor, text: optionText } = getTagColor(
                modalColor,
                i,
                true
              );

              const { bgColor: labelColor, text: labelText } = getTagColor(
                modalColor,
                i,
                true
              );

              return {
                value: item?.name,
                label: item?.name,
                color: optionColor,
                text: optionText,
                labelColor,
                labelText,
              };
            })
          : [],
        invoiceDate:
          invoice.invoiceDate !== "" && invoice.invoiceDate
            ? invoice.invoiceDate
            : initialValues?.invoiceDate,
        dueDate:
          invoice.dueDate !== "" && invoice.dueDate
            ? invoice.dueDate
            : selectedDueDate,
        paymentMethod: invoice.paymentMethod || "Bank",
        paymentLink: invoice.paymentLink,
        bankDetails: invoice.bankDetails,
        taxes: invoice.taxes?.length ? invoice.taxes : initialValues?.taxes,
        services: invoice.services?.length
          ? invoice.services.map((item) => {
              return {
                serviceType: item.serviceType,
                serviceName: item.serviceName,
                servicePrice: item.servicePrice,
                quantity: item.quantity,
              };
            })
          : [],
        vatPercentage:
          invoice.vatPercentage === 0 ? null : invoice.vatPercentage || null,
        discount: invoice.discount === 0 ? null : invoice.discount || null,
        invoiceNumber: invoice.invoiceNumber || "",
        billingType: invoice.billingType || "standard",
        status: invoice.status || "draft",
        currency: invoice.currency || "USD",
      });
      if (invoice.billingType === "recurring") {
        setType(invoice.billingType);
        setIntervall(invoice.interval);
        setRepeats(invoice.repeats);
        setStartDate(moment(invoice?.startDate).format("DD/MM/YYYY"));
        if (invoice?.endDate) {
          // const date = new Date(invoice?.endDate);
          setEndDate(moment(invoice?.endDate).format("DD/MM/YYYY"));
        }
      } else {
        setType("standard");
        setIntervall("weekly");
        setRepeats(1);
        setStartDate(new Date().toLocaleDateString());
      }
      if (invoice.status === "sent") {
        setIsCreateInvoice(true);
      }
    }
  }, [
    initialValues?.invoiceDate,
    initialValues?.taxes,
    invoice,
    invoiceId,
    selectedDueDate,
  ]);

  function roundToTwoDecimals(value) {
    if (isNaN(value)) return 0;
    return Math.round(value * 100) / 100;
  }

  const getDiscount = (subtotal, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount);
    if (isNaN(discountNum) || discountNum === 0 || isNaN(subtotalNum)) return 0;
    return roundToTwoDecimals((subtotalNum * discountNum) / 100);
  };

  const getVat = (subtotal, vatPercentage, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const vatPercentageNum = Number(vatPercentage);
    if (isNaN(vatPercentageNum) || vatPercentageNum === 0 || isNaN(subtotalNum))
      return 0;
    const discountedAmount = subtotalNum - getDiscount(subtotalNum, discount);
    return roundToTwoDecimals((discountedAmount * vatPercentageNum) / 100);
  };

  const getTotalDue = (invoice, discount, vatPercentage, taxes, isVAT) => {
    const subtotal = invoice?.services?.reduce((acc, service) => {
      if (service?.serviceType === "Fixed") {
        return acc + Number(service?.servicePrice);
      } else {
        return acc + Number(service?.quantity) * Number(service?.servicePrice);
      }
    }, 0);
    const subTotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount) || 0;
    const vatPercentageNum = Number(vatPercentage) || 0;

    const taxesValid = Array.isArray(taxes) && taxes.length > 0;

    if (isVAT) {
      return roundToTwoDecimals(
        subTotalNum -
          getDiscount(subTotalNum, discountNum) +
          getVat(subTotalNum, vatPercentageNum, discountNum)
      );
    } else if (taxesValid) {
      const totalTaxes = taxes.reduce((total, tax) => {
        const taxValueNum = Number(tax.taxValue) || 0;
        return total + getVat(subTotalNum, taxValueNum, discountNum);
      }, 0);
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum) + totalTaxes
      );
    } else {
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum)
      );
    }
  };

  const handlePaypal = () => {
    const paymentLink = dispatch(
      PaypalOrderCreate(invoiceId, setLoadingPaymentLink)
    );
    return paymentLink;
  };

  const handleStripe = () => {
    const paymentLink = dispatch(
      stripeOrderCreate(invoiceId, setLoadingPaymentLink)
    );
    return paymentLink;
  };

  const updatePaypalOrder = () => {
    const orderId = invoice?.paymentLink.split("?")[1].split("=")[1];
    dispatch(PaypalOrderUpdate(invoiceId, orderId));
  };

  const fetchStripeInfo = useCallback(
    async (code) => {
      try {
        setLoadingStripeLink(true);
        const result = await axios.post("api/v1/stripe/token", {
          grant_type: "authorization_code",
          code: code,
        });
        searchParams.delete("code");
        searchParams.delete("scope");
        searchParams.delete("state");
        setSearchParams(searchParams);
        setLoadingStripeLink(false);

        dispatch({
          type: "SET_USER_DATA",
          payload: {
            data: { ...user, stripeInfo: result?.data.oauth },
          },
        });
      } catch (error) {
        setLoadingStripeLink(false);
        toast.error("Error with stripe!");
      }
    },
    [dispatch, searchParams, setSearchParams, user]
  );

  const fetchPaypalInfo = useCallback(
    async (code) => {
      try {
        setLoadingPaymentLink(true);
        const result = await axios.post("api/v1/paypal/token", {
          code: code,
        });
        searchParams.delete("code");
        searchParams.delete("scope");
        setSearchParams(searchParams);
        setLoadingPaymentLink(false);

        dispatch({
          type: "SET_USER_DATA",
          payload: {
            data: { ...user, payPalInfo: result?.data },
          },
        });
      } catch (error) {
        setLoadingPaymentLink(false);
        toast.error(error?.response?.statusText);
        return error;
      }
    },
    [dispatch, searchParams, setSearchParams, user]
  );

  useEffect(() => {
    if (cardId && params.size === 3) {
      fetchStripeInfo(cardId);
    } else if (cardId && params.size === 2) {
      fetchPaypalInfo(cardId);
    }
  }, [cardId, fetchPaypalInfo, fetchStripeInfo, params.size]);

  const handleSubmitFunction = (
    values,
    { setErrors, setSubmitting, setTouched }
  ) => {
    setTouched({
      invoiceName: true,
      categoryTags: true,
    });
    delete values.step;
    setSubmitting(true);

    if (currentStep === 5 && isCreateInvoice) {
      values.status = "sent";
    }

    const payload = {
      ...values,
      categoryTags: values.categoryTags.map((item) => {
        return {
          name: item.value,
          color: {
            color: item.color,
            text: item.text,
            labelColor: item.labelColor,
            labelText: item.labelText,
          },
        };
      }),
    };

    if (invoiceId) {
      const totalDue = getTotalDue(
        payload,
        payload.discount,
        payload.vatPercentage,
        payload.taxes,
        payload.is_VAT
      );
      payload.totalDue = totalDue;
      if (currentStep === 5) {
        // const totalDue = getTotalDue(
        //   payload,
        //   payload.discount,
        //   payload.vatPercentage,
        //   payload.taxes,
        //   payload.is_VAT
        // );
        // payload.totalDue = totalDue;
        // if (values.services) {
        //   handlePaypal();
        // }
      }

      if (initialValues?.discount !== payload?.discount) {
        const totalDue = getTotalDue(
          payload,
          payload.discount,
          payload.vatPercentage,
          payload.taxes,
          payload.is_VAT
        );
        payload.totalDue = totalDue;
      }

      const checkRequiredFields = allRequiredFields.every((field) => {
        if (payload[field] === "") {
          return false;
        }
        if (field === "services") {
          if (payload[field].length === 0) {
            return false;
          }
        }
        return true;
      });

      if (checkRequiredFields) {
        payload.status = "unsent";
      }

      payload.client = clientId;
      payload.vatPercentage = payload?.is_VAT ? payload?.vatPercentage : 0;
      payload.taxes = payload?.is_VAT ? [] : payload?.taxes;
      payload.bankDetails =
        payload?.paymentMethod === "Bank" ? payload?.bankDetails : "";
      payload.paymentLink =
        payload?.paymentMethod === "Paypal" ||
        payload?.paymentMethod === "Stripe" ||
        payload?.paymentMethod === "Custom"
          ? payload?.paymentLink
          : null;

      dispatch(
        updateInvoice(
          invoiceId,
          payload,
          navigate,
          setErrors,
          setSubmitting,
          currentStep,
          setCurrentStep,
          handlePaypal,
          handleStripe
        )
      );
      const RESET_ACTIVE_TAB_TIME = 1200; // In milliseconds
      setTimeout(() => setActiveFocusedTab(""), RESET_ACTIVE_TAB_TIME);
      return;
    }
    payload.client_id = clientId;
    // dispatch(createInvoice(payload, navigate, setErrors, setSubmitting));
  };

  const handleSetStep = (step) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowRecurringBannerMenu(false);
        setShowDeleteConfirmationModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <AdminNavbar
        title="Create Invoice"
        subtitle="Create, build and send a new invoice to your client"
      />
      {type === "recurring" && (
        <div className="px-8 py-4">
          <div className="flex items-center w-full gap-2 px-8 py-4 bg-indigo-100 rounded-full">
            <span className="flex items-center w-full gap-2 text-sm font-medium text-gray-700">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.875 7.12402V10.874H15.125"
                  stroke="#5281E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.125 17.124V13.374H8.875"
                  stroke="#5281E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.69375 10.2485C7.01073 9.35276 7.54946 8.5519 8.25967 7.92066C8.96988 7.28942 9.82842 6.84837 10.7552 6.63868C11.6819 6.42899 12.6467 6.45748 13.5595 6.7215C14.4723 6.98553 15.3033 7.47647 15.975 8.14852L18.875 10.8735M5.125 13.3735L8.025 16.0985C8.69672 16.7706 9.52773 17.2615 10.4405 17.5255C11.3533 17.7896 12.3181 17.8181 13.2448 17.6084C14.1716 17.3987 15.0301 16.9576 15.7403 16.3264C16.4505 15.6951 16.9893 14.8943 17.3062 13.9985"
                  stroke="#5281E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>
                Recurs <span className="font-semibold">{interval}</span>, ends{" "}
                <span className="font-semibold">
                  {interval === "indefinitely"
                    ? endDate
                    : getEndDate(startDate, interval, repeats, userDateFormat)}
                </span>
              </span>
            </span>
          </div>
        </div>
      )}
      {isLoading ? (
        <InvoiceLoader />
      ) : (
        <InvoiceForm
          initialValues={initialValues}
          validationSchema={validationSchema}
          handleSubmitFunction={handleSubmitFunction}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isCreateInvoice={isCreateInvoice}
          setIsCreateInvoice={setIsCreateInvoice}
          loadingPaymentLink={loadingPaymentLink}
          selectedDueDate={selectedDueDate}
          setSelectedDueDate={setSelectedDueDate}
          invoice={invoice}
          user={user}
          setActiveFocusedTab={setActiveFocusedTab}
          activeFocusedTab={activeFocusedTab}
          handlePaypal={handlePaypal}
          handleStripe={handleStripe}
          clientId={clientId}
          invoiceId={invoiceId}
        />
      )}
      <EditInvoiceAndInvoiceTemplateModal
        isOpen={isOpenTemplateModal}
        setIsOpen={setIsOpenTemplateModal}
        type="Invoice"
        editData={initialValues}
        isCreateInvoice={true}
      />
    </>
  );
}

export default CreateInvoice;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function useBgAccent() {
  const userProfile = useSelector((state) => state?.auth?.user);
  const [accentColor, setAccentColor] = useState("#6D6DF2"); // for bg-AccentRegular : #6D6DF2

  useEffect(() => {
    const accent_color = userProfile?.brandIdentity?.accent_color;
    if (accent_color) {
      setAccentColor(accent_color);
    }else{
      setAccentColor('#6D6DF2')
    }
  }, [userProfile?.brandIdentity?.accent_color, accentColor]);

  return accentColor;
}

export default useBgAccent;
